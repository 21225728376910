import React, { useContext, useEffect } from 'react';

import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { Redirect, Route } from 'react-router-dom';

import { ViewPrivacy } from './View/ViewPrivacy/ViewPrivacy';
import { ViewTerms } from './View/ViewTerms/ViewTerms';
import { ViewRoadmap } from './View/ViewRoadmap/ViewRoadmap';
import { ViewReferral } from './View/ViewReferral/ViewReferral';
import { ViewData } from './View/ViewData/ViewData';
import { ViewAuth } from './View/ViewAuth/ViewAuth';
import { ViewOffer } from './View/ViewOffer/ViewOffer';

import { deviceType } from 'react-device-detect';
import { DataProvider } from './Providers/DataProvider';
import { ViewOfferSuccess } from './View/ViewOfferSuccess/ViewOfferSuccess';
import { ViewPaused } from './View/ViewPaused/ViewPaused';
import { ViewNew } from './View/ViewNew/ViewNew';
import { ViewNewDesktop } from './View/ViewNew/ViewNewDesktop';


// https://daily-dev-tips.com/posts/ionic-tab-bar-circle-middle-button/
const App = () => {
	console.log(deviceType)
	return (

		<IonReactRouter>
			<DataProvider>
				<Route exact={true} path="/">
					<IonApp className="main-app">
					{deviceType == "mobile" ? <ViewNew /> : <ViewNewDesktop/>}
					</IonApp>
				</Route>

				{/* <Route exact={true} path="/new">
					<IonApp className="main-app">
						{deviceType == "mobile" ? <ViewNew /> : <ViewNewDesktop/>}
					</IonApp>
				</Route> */}


				<Route exact={true} path="/roadmap">
					<IonApp className="main-app">
						<ViewRoadmap />
					</IonApp>
				</Route>

				<Route exact={true} path="/privacy">
					<IonApp className="main-app">
						<ViewPrivacy />
					</IonApp>
				</Route>

				<Route exact={true} path="/restaurant">
					<IonApp className="main-app">
						<ViewData />
					</IonApp>
				</Route>

				<Route exact={true} path="/terms">
					<IonApp className="main-app">
						<ViewTerms />
					</IonApp>
				</Route>

				<Route exact={true} path="/referral">
					<IonApp className="main-app">
						<ViewReferral />
					</IonApp>
				</Route>

				<Route exact={true} path="/auth">
					<IonApp className="main-app">
						<ViewAuth />
					</IonApp>
				</Route>

				<Route exact={true} path="/service-paused">
					<IonApp className="main-app">
						<ViewPaused />
					</IonApp>
				</Route>

				{/* <Route exact={true} path="/offer">
					<IonApp className="main-app">
						<ViewOffer />
					</IonApp>
				</Route> */}

				<Route exact={true} path="/offer-success">
					<IonApp className="main-app">
						<ViewOfferSuccess />
					</IonApp>
				</Route>

				{/* 
				<Route exact={true} path="/reservation-madness">
					<IonApp className="main-app">
						<ViewBracket />
					</IonApp>
				</Route> */}

				{/* <Redirect exact from="*" to="/" /> */}
			</DataProvider>
		</IonReactRouter>

	)
}

export default App;